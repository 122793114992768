import { IMAGES } from 'assets/images'
import { Section, SectionWrapper } from 'components/Common/Containers'
import Reveal from 'components/Common/Reveal'
import { Heading4, TextNormal } from 'components/Common/Typography'
import HeadingSection from 'components/Sections/HeadingSection'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import styled from 'styled-components'

const dataSlide = [
  {
    user: {
      image: IMAGES.TESTIMONIAL1,
      name: 'Rodrigo Quan Miranda',
      position: 'Co-founder @MantraDAO',
    },
  },
  {
    user: {
      image: IMAGES.TESTIMONIAL2,
      name: 'Rene Labarthe',
      position: 'President of International Affairs @<br/>BLOK BioScience LatAm',
    },
  },
  {
    user: {
      image: IMAGES.TESTIMONIAL3,
      name: 'Dao Duy Tan',
      position: 'Founder @Evotek Vietnam JSC',
    },
  },
]
const Testimonials = () => {
  const { t } = useTranslation()
  const refSlider = useRef<Slider | null>(null)
  const [heightCard, setHeightCard] = useState<undefined | number>(undefined)
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb',
    appendDots: (dots: any) => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: () => <div className="ft-slick__dots--custom"></div>,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  useEffect(() => {
    const setHeightElement = () => {
      const slickTrack = document.querySelector('.slick-track')
      if (slickTrack) {
        const { height } = slickTrack?.getBoundingClientRect()
        setHeightCard(height)
      }
    }
    setHeightElement()
    window.addEventListener('resize', setHeightElement)
    return () => {
      window.removeEventListener('resize', setHeightElement)
    }
  }, [])
  return (
    <TestimonialsWrapper>
      <Section>
        <DivFlex>
          <RichTextLeft>
            <Reveal>
              <HeadingSection content={t('home.testimonials.heading')} />
            </Reveal>
            <Reveal>
              <Description>{t('home.testimonials.description')}</Description>
            </Reveal>
          </RichTextLeft>
          <ListCard>
            <Slider {...settings} ref={refSlider}>
              {dataSlide.map((data, index) => {
                const { user } = data
                return (
                  <CardItem key={index}>
                    <CardItemBox heightCard={heightCard}>
                      <img src={'/assets/svg/icon-quote.svg'} alt={'quote'} />
                      <InfoUser>
                        <Quote>{t(`home.testimonials.dataSlide.${index}.quote`)}</Quote>
                        <img src={user.image} alt={'image_user'} />
                      </InfoUser>
                      <InfoAnother>
                        <Name>{user.name}</Name>
                        <PositionText dangerouslySetInnerHTML={{ __html: user.position }} />
                      </InfoAnother>
                    </CardItemBox>
                  </CardItem>
                )
              })}
            </Slider>
          </ListCard>
        </DivFlex>
      </Section>
    </TestimonialsWrapper>
  )
}
const TestimonialsWrapper = styled(SectionWrapper)`
  width: 100%;
`
const DivFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const RichTextLeft = styled.div`
  flex: 0 0 300px;
  max-width: 300px;
  padding-right: 12px;
  margin-top: 20px;
`
const Description = styled(TextNormal)`
  margin-top: 16px;
`
const ListCard = styled.div`
  flex: 0 0 calc(100% - 300px);
  max-width: calc(100% - 300px);
  .slick-dots.slick-thumb {
    width: max-content;
    top: 130px;
    left: -300px;
    ul {
      display: flex;
      padding-left: 0;
    }
  }
  .slick-slide {
    padding-left: 12px;
    padding-right: 12px;
  }
  .ft-slick__dots--custom {
    height: 8px;
    width: 14px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;
    position: relative;
  }

  .slick-dots li {
    width: 14px;
    margin: 0 2px;
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active {
    width: 40px;
    transition: width 0.3s ease-in-out;
  }
  .slick-dots .slick-active .ft-slick__dots--custom {
    width: 40px;
    top: 0;
    overflow: hidden;
    background-color: ${({ theme }) => theme.primary};
  }
  @media screen and (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 40px;
    .slick-dots.slick-thumb {
      width: max-content;
      top: -40px;
      left: 0;
    }
  }
`
const CardItem = styled.div``
const CardItemBox = styled.div<{ heightCard: undefined | number }>`
  padding: 24px;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  height: ${({ heightCard }) => (heightCard ? `${heightCard}px` : '100%')};
  & > img {
    width: 20px;
    height: 20px;
  }
`
const InfoUser = styled.div`
  img {
    width: 150px;
    height: 150px;
    display: block;
    margin: 0 auto;
    border-radius: 200px;
    object-fit: cover;
  }
`
const Quote = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 17px;
  margin-bottom: 24px;
  text-align: center;
`
const InfoAnother = styled.div``
const Name = styled(Heading4)`
  color: ${({ theme }) => theme.primary};
  margin-bottom: 8px;
  text-align: center;
`
const PositionText = styled.p`
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 17px;
  text-align: center;
`
export default Testimonials
