import { ButtonPrimary } from 'components/Common/Button'
import Card from 'components/Common/Card'
import { Container, SectionWrapper } from 'components/Common/Containers'
import Reveal from 'components/Common/Reveal'
import { Heading4 } from 'components/Common/Typography'
import HeadingSection from 'components/Sections/HeadingSection'
import { PathConstants } from 'constants/PathConstants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { removeDoubleApostrophe } from 'utils/removeDoubleApostrophe'

const OurProducts = () => {
  const { t } = useTranslation()
  return (
    <OurProductsWrapper>
      <Container>
        <Product>
          <ProductItem>
            <DivBorder>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </DivBorder>
            <Card
              service={{
                href: PathConstants.PRODUCTS,
                title: removeDoubleApostrophe(JSON.stringify(t('home.ourProduct.product.title'))),
                text: removeDoubleApostrophe(JSON.stringify(t('home.ourProduct.product.text'))),
                srcImg: '/assets/svg/icon-five_star.svg',
                link: removeDoubleApostrophe(JSON.stringify(t('home.ourProduct.product.link'))),
              }}
              secondary={true}
            />
          </ProductItem>
          <ProductContent>
            <Reveal>
              <HeadingSection content={t('home.ourProduct.heading')} />
            </Reveal>
            <InformationProduct>
              <Reveal>
                <p>{t('home.ourProduct.information.text1')}</p>
              </Reveal>
              <Reveal>
                <Heading4>{t('home.ourProduct.information.heading4')}</Heading4>
              </Reveal>
              <Reveal>
                <p>{t('home.ourProduct.information.text2')}</p>
              </Reveal>
            </InformationProduct>
            <a href={'https://dandelionlabs.io/token-linear-vesting/'} target="_blank" rel="noreferrer">
              <ButtonPrimary>{t('button.explore')}</ButtonPrimary>
            </a>
          </ProductContent>
        </Product>
      </Container>
    </OurProductsWrapper>
  )
}
const OurProductsWrapper = styled(SectionWrapper)``
const Product = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ProductItem = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  .card {
    flex: 0 0 50%;
    max-width: 50%;
    margin-left: -10px;
  }
  @media only screen and (max-width: 991px) {
    .card {
      flex-basis: 60%;
      max-width: 60%;
    }
    .card .card-image {
      img {
        max-width: 100px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-basis: 80%;
    max-width: 80%;
    margin: 24px auto 0;
    order: 2;
  }
  @media screen and (max-width: 575px) {
    flex-basis: 100%;
    max-width: 100%;
    .card {
      flex-basis: 70%;
      max-width: 70%;
    }
    .card .card-image {
      img {
        max-width: 60px;
      }
    }
  }
`
const DivBorder = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  height: 100%;
  div {
    border: 1.5px solid ${({ theme }) => theme.primary};
    border-radius: 12px 0 0 12px;
    flex: 0 0 25%;
    max-width: 25%;
    background-color: ${({ theme }) => theme.dark};
    &:not(:first-of-type) {
      margin-left: -2px;
    }
  }
  @media only screen and (max-width: 991px) {
    flex-basis: 40%;
    max-width: 40%;
  }
  @media only screen and (max-width: 575px) {
    flex-basis: 30%;
    max-width: 30%;
  }
`
const ProductContent = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 80px;
  align-self: center;
  @media screen and (max-width: 991px) {
    padding-left: 24px;
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }
`
const InformationProduct = styled.div`
  margin: 20px 0;
  h4 {
    margin-top: 8px;
    margin-bottom: 20px;
  }
`
export default OurProducts
