import React from 'react'
import styled from 'styled-components'

const Index = (props: any) => {
  const { children } = props
  return (
    <CardDotted className={'card-dotted'}>
      <BorderTop />
      {children}
      <BorderBottom />
    </CardDotted>
  )
}
const CardDotted = styled.div`
  padding: 24px;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 20px;
  }
`
const BorderTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  right: 0;
  height: 60px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 58px;
    width: 58px;
    border-style: solid;
    border-color: ${({ theme }) => theme.primary};
  }
  &::before {
    left: 0;
    border-radius: 12px 0 0 0;
    border-width: 2px 0 0 2px;
  }
  &::after {
    right: 0;
    border-radius: 0 12px 0 0;
    border-width: 2px 2px 0 0;
  }
`
const BorderBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  right: 0;
  height: 60px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 58px;
    width: 58px;
    border-style: solid;
    border-color: ${({ theme }) => theme.primary};
  }
  &::after {
    right: 0;
    border-radius: 0 0 12px 0;
    border-width: 0 2px 2px 0;
  }
  &::before {
    left: 0;
    border-radius: 0 0 0 12px;
    border-width: 0 0 2px 2px;
  }
`
export default Index
