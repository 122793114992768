import styled, { css } from 'styled-components'

const RowCs = styled.div<{ space?: number; noSpace?: boolean | undefined }>`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${(props) => (props.space ? `${props.space}px` : '-10px')};
  margin-left: ${(props) => (props.space ? `${props.space}px` : '-10px')};
  ${({ noSpace }) =>
    noSpace &&
    css`
      margin-right: 0;
      margin-left: 0;
    `}
`
export default RowCs
