import { ButtonPrimary } from 'components/Common/Button'
import { Heading4, TextSmall } from 'components/Common/Typography'
import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
interface CardType {
  service: {
    title: string
    text: string
    srcImg?: string
    link?: string
    href?: string
  }
  secondary?: boolean
}

const imageAnimate = {
  offscreen: { y: 100 },
  onscreen: {
    y: 0,
    transition: { duration: 0.5, delay: 1 },
  },
}
const textAnimate = {
  offscreen: {
    y: 100,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delay: 1 },
  },
}
const textAnimate2 = {
  offscreen: {
    y: 50,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delay: 1.3 },
  },
}
const Card = (props: CardType) => {
  const { service, secondary = false } = props
  return (
    <motion.div
      initial={'offscreen'}
      whileInView={'onscreen'}
      viewport={{ once: true }}
      transition={{ staggerChildren: 0.5 }}
      className={'card'}
    >
      <CardWrapper secondary={secondary}>
        <CardTitle secondary={secondary}>
          <motion.span variants={textAnimate} style={{ display: 'block' }}>
            {service.title}
          </motion.span>
        </CardTitle>
        <Text secondary={secondary}>
          <motion.span variants={textAnimate2} style={{ display: 'block' }}>
            {service.text}
          </motion.span>
        </Text>
        {service?.srcImg && (
          <BoxImg className={'card-image'}>
            <motion.div variants={imageAnimate}>
              <img src={service.srcImg} alt={'img-desc'} />
            </motion.div>
          </BoxImg>
        )}

        {service?.link && service?.href && (
          <Link to={service.href}>
            <LearnMore>{service.link}</LearnMore>
          </Link>
        )}
      </CardWrapper>
    </motion.div>
  )
}
const CardWrapper = styled.div<{ secondary: boolean }>`
  padding: 24px;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 12px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  ${({ secondary }) =>
    secondary &&
    css`
      background-color: ${({ theme }) => theme.primary};
    `};
  @media only screen and (max-width: 575px) {
    padding: 16px;
  }
`
const CardTitle = styled(Heading4)<{ secondary: boolean }>`
  margin-bottom: 16px;
  ${({ secondary }) =>
    secondary &&
    css`
      color: ${({ theme }) => theme.dark};
    `}
`
const Text = styled(TextSmall)<{ secondary: boolean }>`
  margin-bottom: 24px;
  ${({ secondary }) =>
    secondary &&
    css`
      color: ${({ theme }) => theme.dark};
    `}
`
const BoxImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
  @media screen and (max-width: 991px) {
    img {
      max-width: 120px;
      margin: 0 auto;
    }
  }
`
const LearnMore = styled(ButtonPrimary)`
  border-radius: 4px;
  padding: 8px;
  max-width: none;
  width: 100%;
  background-color: ${({ theme }) => theme.primary};
  margin-top: 32px;
  @media screen and (max-width: 991px) {
    margin-top: 20px;
  }
`
export default Card
