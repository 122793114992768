import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

import { contactSlice } from './contactState'

const rootReducer = combineReducers({
  [contactSlice.reducerPath]: contactSlice.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(contactSlice.middleware),
})
setupListeners(store.dispatch)
export default store
