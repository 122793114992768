import ColCs from 'components/Common/ColCs'
import { Section, SectionWrapper } from 'components/Common/Containers'
import RowCs from 'components/Common/RowCs'
import SectionIntroduce from 'components/Sections/SectionIntroduce'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { removeDoubleApostrophe } from 'utils/removeDoubleApostrophe'

import CardCareer from './CardCareer'

const careers: { image: string }[] = [
  {
    image: '/assets/svg/careers/architect.svg',
  },
  {
    image: '/assets/svg/careers/full_stack.svg',
  },
  {
    image: '/assets/svg/careers/angular.svg',
  },
  {
    image: '/assets/svg/careers/react.svg',
  },
  {
    image: '/assets/svg/careers/php.svg',
  },
  {
    image: '/assets/svg/careers/paralegal.svg',
  },
  {
    image: '/assets/svg/careers/blockchain.svg',
  },
  {
    image: '/assets/svg/careers/ba.svg',
  },
  {
    image: '/assets/svg/careers/community-manager.svg',
  },
]
const Careers = () => {
  const { t } = useTranslation()
  return (
    <CareersWrapper>
      <Section>
        <SectionIntroduce
          content={{
            subTitle: removeDoubleApostrophe(JSON.stringify(t('careers.content.subTitle'))),
            title: removeDoubleApostrophe(JSON.stringify(t('careers.content.title'))),
            description: removeDoubleApostrophe(JSON.stringify(t('careers.content.description'))),
            link: {
              href: '/',
              name: removeDoubleApostrophe(JSON.stringify(t('button.explore'))),
            },
          }}
          image={'/assets/svg/careers.svg'}
        />
      </Section>
      <SectionWrapper>
        <Section>
          <ListCareers>
            <RowCs space={-30} className={'card-row'}>
              {Array.from(Array(9).keys()).map((index: number) => {
                const career = {
                  name: removeDoubleApostrophe(JSON.stringify(t(`careers.careerList.${index}.name`))),
                  information: removeDoubleApostrophe(JSON.stringify(t(`careers.careerList.${index}.information`))),
                  image: careers[index].image,
                }
                return (
                  <ColCs key={index} gutter={30} xs={12} sm={6} lg={4} className={'card-col'}>
                    <CardCareer career={career} />
                  </ColCs>
                )
              })}
            </RowCs>
          </ListCareers>
        </Section>
      </SectionWrapper>
    </CareersWrapper>
  )
}
const CareersWrapper = styled.div`
  width: 100%;
`
const ListCareers = styled.div`
  .card-col {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .card-dotted {
    height: 100%;
  }
  @media screen and (max-width: 1024px) {
    .card-col {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    .card-row {
      margin-left: -10px;
      margin-right: -10px;
    }
    .card-col {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`
export default Careers
