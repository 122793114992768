import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React from 'react'
import styled from 'styled-components'

import Footer from './Footer'
import Navbar from './Navbar'

type Props = {
  children: any
}
const Layout = ({ children }: Props) => {
  return (
    <>
      <Background />
      <Navbar />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}
const Main = styled.main`
  position: relative;
  padding-top: 215px;
  z-index: 1;
  @media screen and (max-width: 1299px) {
    padding-top: 150px;
  }
`
export default Layout

const Background = styled.div`
  @keyframes gradient {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0 0;
    }
  }
  position: fixed;
  height: 100vh;
  width: 100vw;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: fixed linear-gradient(315deg, #07172e 3%, #0c2b57 38%, #173257 68%, #1b437c 98%);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  z-index: 0;

  .wave {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 15em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
  }

  .wave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
  }

  @keyframes wave {
    2% {
      transform: translateX(1);
    }

    25% {
      transform: translateX(-25%);
    }

    50% {
      transform: translateX(-50%);
    }

    75% {
      transform: translateX(-25%);
    }

    100% {
      transform: translateX(1);
    }
  }
`
