import { TextNormal } from 'components/Common/Typography'
import styled from 'styled-components'

export const ToastError = styled(TextNormal)`
  color: ${({ theme }) => theme.red};
`
export const ToastSuccess = styled(TextNormal)`
  color: ${({ theme }) => theme.green};
`
export const ToastWarning = styled(TextNormal)`
  color: ${({ theme }) => theme.orange};
`
