import Another1 from './image/another-1-logo.png'
import Arttaca from './image/Arttaca.png'
import BlockChainHaNoi from './image/blockchain-hanoi-logo.png'
import Logo from './image/dandelionlabs_logo.png'
import Degen from './image/Degen.png'
import ETHeader from './image/ET-Header-logo.png'
import Evotek from './image/evotek_logo.png'
import LANSpace from './image/Lan-Space.png'
import LanCapital from './image/Lan-to-Capital-logo.png'
import Metamask from './image/metamask.png'
import Oxychain from './image/oxychain-logo.png'
import Oxyfarms from './image/OxyFarms-dandelion-labs.png'
import Testimonial3 from './image/partners/dao-duy-tan.jpeg'
import Testimonial2 from './image/partners/rene-labarthe.jpeg'
import Testimonial1 from './image/partners/rodrigo-quan.jpeg'
import Reminiscense from './image/reminiscense-logo.png'
import SMU from './image/SMU_ASEAN-Internship-logo.jpg'
import Superalogs from './image/superalgos-logo.png'
import TheStormNetwork from './image/the-storm-network.png'
import Tss from './image/tss-logo.png'
import VSMAC from './image/VSMAC.png'
import WalletConnect from './image/wallet_connect.png'
const IMAGES = {
  LOGO: Logo,
  METAMASK: Metamask,
  WALLET_CONNECT: WalletConnect,
  EVOTEK: Evotek,
  THE_STORM_NETWORK: TheStormNetwork,
  REMINISCENSE: Reminiscense,
  BLOCKCHAIN_HANOI: BlockChainHaNoi,
  ET_HEADER: ETHeader,
  SMU,
  OXYCHAIN: Oxychain,
  SUPERLOGS: Superalogs,
  LAN_CAPITAL: LanCapital,
  TSS: Tss,
  OXY_FARMS: Oxyfarms,
  ANOTHER1: Another1,
  ARTTACA: Arttaca,
  VSMAC,
  DEGEN: Degen,
  LAN_SPACE: LANSpace,
  TESTIMONIAL1: Testimonial1,
  TESTIMONIAL2: Testimonial2,
  TESTIMONIAL3: Testimonial3,
}
export { IMAGES }
