import ColCs from 'components/Common/ColCs'
import { SectionWrapper } from 'components/Common/Containers'
import RowCs from 'components/Common/RowCs'
import React from 'react'
import styled from 'styled-components'

const gallery = [
  {
    srcImg: '/assets/svg/services/service1.svg',
  },
  {
    srcImg: '/assets/svg/services/service2.svg',
  },
  {
    srcImg: '/assets/svg/services/service3.svg',
  },
  {
    srcImg: '/assets/svg/services/service4.svg',
  },
  {
    srcImg: '/assets/svg/services/service5.svg',
  },
  {
    srcImg: '/assets/svg/services/service1.svg',
  },
]
const Gallery = () => {
  return (
    <GalleryWrapper>
      <RowCs noSpace={true} className={'row-gallery'}>
        {gallery.map((item, index) => {
          return (
            <ColCs xs={2} key={index} noGutter={true} className={'col-gallery'}>
              <BoxImg>
                <img src={item.srcImg} alt={'img-gallery'} />
              </BoxImg>
            </ColCs>
          )
        })}
      </RowCs>
    </GalleryWrapper>
  )
}
const GalleryWrapper = styled(SectionWrapper)`
  .row-gallery {
    margin-left: 0;
    margin-right: 0;
  }
  .col-gallery {
    padding-left: 0;
    padding-right: 0;
  }
`
const BoxImg = styled.div`
  width: 100%;
  max-height: 250px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
`
export default Gallery
