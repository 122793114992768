import { ButtonPrimary } from 'components/Common/Button'
import { FormInputSecondary } from 'components/Common/Form'
import { TextNormal } from 'components/Common/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const BlockRegisterInformation = () => {
  const { t } = useTranslation()
  return (
    <RegisterInformationWrapper>
      <RegisterInformationContainer>
        <RegisterInformationMain>
          <RegisterInformationContent>
            <TextNormal>{t('blockRegister.register')}</TextNormal>
            <DivForm>
              <FormInputSecondary placeholder={t('blockRegister.form.email.placeholder')} />
              <ButtonPrimary>{t('button.subscribe')}</ButtonPrimary>
            </DivForm>
          </RegisterInformationContent>
          <Image>
            <img src={'/assets/svg/firework-part.svg'} alt={'iconic'} />
          </Image>
        </RegisterInformationMain>
      </RegisterInformationContainer>
    </RegisterInformationWrapper>
  )
}
const RegisterInformationWrapper = styled.div``
const RegisterInformationContainer = styled.div`
  padding-top: 190px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 130px;
    background: url('/assets/svg/keepup.svg') repeat-x center;
  }
  @media screen and (max-width: 767px) {
    padding-top: 80px;
    &::before {
      height: 60px;
      background-size: cover;
    }
  }
`
const RegisterInformationMain = styled.div`
  width: 100%;
  display: flex;
  max-width: 895px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 991px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 24px;
    padding-right: 24px;
    flex-wrap: wrap;
  }
`
const RegisterInformationContent = styled.div`
  flex: 0 0 55%;
  max-width: 55%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
  }
`
const DivForm = styled.div`
  display: flex;
  input {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
const Image = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding-left: 50px;
  img {
    display: block;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    order: 1;
    img {
      max-width: 250px;
      width: 100%;
      margin: 0 auto 30px;
    }
  }
`
export default BlockRegisterInformation
