export const BASE_URL = `${process.env.REACT_APP_URL_API}`

export const defaultParameters = {
  baseUrl: BASE_URL,
  prepareHeaders: (headers: Headers, { getState }: { getState: any }) => {
    headers.set('Access-Control-Allow-Origin', '*')
    const rootState: any = getState()
    const { userManagerSlice } = rootState
    if (userManagerSlice.tokenState) {
      headers.set('authorization', `Bearer ${userManagerSlice.tokenState}`)
      headers.set('Content-Type', 'application/json')
    }
    return headers
  },
}
