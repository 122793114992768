import 'react-toastify/dist/ReactToastify.css'

import { StrictMode } from 'react'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import store from 'state/store'

import App from './App'
import i18n from './i18n'
import ThemeProvider, { ThemedGlobalStyle } from './theme'
const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <StrictMode>
    <ThemeProvider>
      <ThemedGlobalStyle />
        <Provider store={store}>
        <BrowserRouter>
            <I18nextProvider i18n={i18n} defaultNS={'translation'}>
              <React.Suspense>
                <App />
              </React.Suspense>
            </I18nextProvider>
        </BrowserRouter>
        </Provider>
      <ToastContainer />
    </ThemeProvider>
  </StrictMode>,
)
