import { ReactComponent as StarSvg } from 'assets/svg/star.svg'
import { gsap, Power1 } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import React, { useEffect } from 'react'
import styled from 'styled-components'

// random between
export const randomBetween = (max: number, min: number) => Math.random() * (max - min + 1) + min
export const rb = (min: number, max: number, withFloor: boolean = true) =>
  withFloor ? Math.floor(randomBetween(max, min)) : randomBetween(max, min)

export const Sun = () => {
  const RAYS_AMOUNT = 10
  const STARS_AMOUNT = 250

  useEffect(() => {
    gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger)

      const rays = document.querySelectorAll('.ray')
      const stars = document.querySelectorAll('.star')
      const sun = document.querySelector('#sun-com')

      // animating rays
      for (let i = 0; i < rays.length; i++) {
        const thisRand = Math.floor(Math.random() * 100) + 10
        const tl = gsap.timeline({ repeat: -1 })
        tl.from(rays[i], 0, { rotation: 0 })
        tl.to(rays[i], thisRand, { rotation: i % 2 == 0 ? 360 : -360 })
      }

      const mainTimeline = gsap.timeline({ repeat: -1 })

      // sun animation
      mainTimeline.from(sun, { background: 'none', boxShadow: 'none' })
      mainTimeline.to(sun, { scale: 0.0001, duration: 0 })
      mainTimeline.to(sun, {
        background: SUN_BACKGROUND,
        boxShadow: SUN_SHADOW,
        rotation: 720,
        scale: 1,
        duration: 2,
        ease: Power1.easeInOut,
      })
      mainTimeline.to(sun, {
        rotation: 360,
        scale: 0.9,
        duration: 5,
        y: '+=100',
        ease: Power1.easeInOut,
        background: SUN_BACKGROUND_TWO,
      })
      mainTimeline.to(sun, {
        rotation: 360,
        scale: 1.2,
        duration: 5,
        y: '-=100',
        ease: Power1.easeInOut,
        background: SUN_BACKGROUND,
      })
      mainTimeline.to(sun, { rotation: 720, scale: 0.0001, duration: 2, ease: Power1.easeInOut })

      // disappearing rays
      for (let i = 0; i < rays.length; i++) {
        mainTimeline.from(rays[i], { background: RAYS_BACKGROUND, boxShadow: RAYS_SHADOW, duration: 0 })
        mainTimeline.to(rays[i], { background: 'transparent', boxShadow: 'none', duration: 0 })
      }

      // removing sun shadow
      mainTimeline.to(sun, { boxShadow: 'none', background: `none`, duration: 0, scale: 1 })

      // moon animation
      mainTimeline.to(sun, { duration: 2, rotation: -135, ease: Power1.easeInOut, boxShadow: MOON_BACKGROUND })

      // removing moon
      mainTimeline.to(sun, {
        rotation: -110,
        scale: 1.05,
        duration: 12,
        y: '+=100',
        ease: Power1.easeIn,
      })
      mainTimeline.to(sun, { rotation: 720, scale: 0.0001, duration: 2, ease: Power1.easeInOut })

      // appearing stars
      for (let i = 0; i < stars.length; i++) {
        const starTimeline = gsap.timeline({ repeat: -1 })
        starTimeline.from(stars[i], {
          opacity: 0,
          x: rb(-800, 800) * (i % 2 == 0 ? -1 : 1),
          y: rb(-400, 400) * (i % 2 == 0 ? -1 : 1),
          delay: 14,
        })
        const delay = rb(0, 7)
        starTimeline.to(stars[i], {
          opacity: 1,
          x: `${i % 2 == 0 ? '+' : '-'}=25`,
          y: `${i % 2 == 0 ? '+' : '-'}=25`,
          delay,
          duration: 8 - delay,
        })
        starTimeline.to(stars[i], {
          opacity: 0,
          x: `${i % 2 == 0 ? '+' : '-'}=25`,
          y: `${i % 2 == 0 ? '+' : '-'}=25`,
          delay,
          duration: 8 - delay,
        })
      }

      // trigger go away animation when scrolling down
      const changeSectionTrigger = gsap.timeline({
        scrollTrigger: {
          trigger: '#service-section',
          start: '-50% 0%',
          end: '120% 0%',
          toggleActions: 'play none none reverse',
        },
      })

      changeSectionTrigger.to(sun, {
        scale: 0.1,
        opacity: 0,
        duration: 2,
        y: '+=200vh',
        x: '+=50vw',
        ease: Power1.easeInOut,
      })

      // window.addEventListener('mousemove', (e) => {
      //   gsap.to(sun, {
      //     duration: 1.5,
      //     overwrite: 'auto',
      //     x: `${e.clientX / 10}`,
      //     y: `${e.clientY / 10}`,
      //     stagger: 0.15,
      //     ease: 'power3.out',
      //   })
      //
      //   const TL = gsap.timeline({
      //     defaults: { duration: 0.5, ease: 'none' },
      //   })
      //
      //   TL.to(sun, {
      //     scale: 1,
      //     overwrite: 'auto',
      //     stagger: { amount: 0.15, from: 'start', ease: 'none' },
      //   })
      //   TL.to(
      //     sun,
      //     {
      //       overwrite: 'auto',
      //       stagger: { amount: 0.15, from: 'end', ease: 'none' },
      //     },
      //     '<+=2.5',
      //   )
      // })
    })
  }, [])
  return (
    <>
      <SunElement id={'sun-com'}>
        {Array.from(Array(RAYS_AMOUNT).keys()).map((index: number) => (
          <RayElement key={index} className="ray" />
        ))}
      </SunElement>
      {Array.from(Array(STARS_AMOUNT).keys()).map((index: number) => (
        <Star key={index} className="star" width={`${randomBetween(3, 10)}px`} />
      ))}
    </>
  )
}

const SUN_BACKGROUND = `linear-gradient(to right, rgba(246, 134, 33, 1) 0%, rgba(255, 255, 255, 0.5) 100%)`
const SUN_BACKGROUND_TWO = `linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(246, 134, 33, 0.8) 100%)`
const SUN_SHADOW = `
  0 0 20px white,
  0 0 30px rgba(246, 134, 33, 1),
  0 0 40px white,
  0 0 50px rgba(246, 134, 33, 1),
  0 0 60px white
`

const RAYS_BACKGROUND = `
  linear-gradient(
    to right,
    transparent 0%,
    transparent 25%,
    rgba(246, 134, 33, 1) 25%,
    rgba(255, 255, 255, 0) 100%
  )
`

const RAYS_SHADOW = `
  0 0 50px white,
  0 0 60px white
`

const MOON_BACKGROUND = `
  inset 0 30px rgba(255, 255, 255, 1),
  inset 0 35px rgba(255, 255, 255, 0.7),
  inset 0 40px rgba(255, 255, 255, 0.5),
  inset 0 45px rgba(255, 255, 255, 0.3),
  inset 0 50px 1px 1px rgba(246, 134, 33, 1)
`

const SunElement = styled.div`
  z-index: 10;
  background: ${SUN_BACKGROUND};
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;

  box-shadow: ${SUN_SHADOW};
`

const RayElement = styled.div`
  width: 200%;
  height: 2px;
  z-index: 9;
  background: ${RAYS_BACKGROUND};
  box-shadow: ${RAYS_SHADOW};
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 0 0 0;
  border-radius: 50%;
  transform-origin: 0 0;
`

const Star = styled(StarSvg)`
  position: absolute;
  opacity: 0;

  path {
    fill: white;
  }
`
