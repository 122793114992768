import { Container } from 'components/Common/Containers'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2}px at right top)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(30px at right top)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}

const Navbar = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [, setScreenWidth] = useState<number>(0)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const handleResize = () => {
      const width = window.innerWidth
      setScreenWidth(width)
      if (width > 767) {
        setOpenMenu(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const handleNavigate = (linkPage: string) => {
    setOpenMenu(false)
    navigate(linkPage)
  }
  return (
    <Header>
      <HeaderBox>
        <Container>
          <Content>
            <Logo>
              <Link to={'/'}>
                <img src="/assets/svg/logo-dandelionlabs.svg" alt={'logo'} />
              </Link>
            </Logo>
            <HamburgerBox
              onClick={() => {
                setOpenMenu(!openMenu)
              }}
              className={openMenu ? 'open' : ''}
            >
              <div className={openMenu ? 'hamburger active' : 'hamburger'}>
                <div className={'divEmpty'}></div>
              </div>
            </HamburgerBox>
            <motion.nav initial={false} animate={openMenu ? 'open' : 'closed'} className="navigation">
              <motion.div className="background" variants={sidebar} />
              <Menu className={'menu'}>
                {Array.from(Array(5).keys()).map((index: number) => {
                  return (
                    <MenuItem key={index}>
                      <LinkCs onClick={() => handleNavigate(t(`navigation.${index}.link`))}>
                        <span className={pathname === t(`navigation.${index}.link`) ? 'active' : ''}>
                          {t(`navigation.${index}.name`)}
                        </span>
                      </LinkCs>
                    </MenuItem>
                  )
                })}
              </Menu>
            </motion.nav>
          </Content>
        </Container>
      </HeaderBox>
    </Header>
  )
}
const Header = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: linear-gradient(to bottom, ${({ theme }) => theme.darkShade}, transparent);
  .background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
  .navigation {
    margin-left: auto;
  }
  @media screen and (max-width: 767px) {
    .background {
      display: block;
    }
  }
`
const HeaderBox = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`
const Content = styled.div`
  display: flex;
  align-items: center;
`
const Logo = styled.div`
  max-width: 160px;
  width: 100%;
  cursor: pointer;
  a {
    display: block;
    text-decoration: none;
  }
  img {
    display: block;
    width: 100%;
    vertical-align: middle;
  }
`
const Menu = styled.ul`
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;

  @media screen and (max-width: 767px) {
    position: relative;
    z-index: 4;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`
const MenuItem = styled.li`
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 17px;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  text-transform: capitalize;
  span {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.white};
    transition: color 0.25s ease-in;
    &:hover {
      color: ${({ theme }) => theme.primary};
    }
    &.active {
      color: ${({ theme }) => theme.primary};
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px 0;
  }
`
const LinkCs = styled.a`
  text-decoration: none;
`

const HamburgerBox = styled.div`
  width: 20px;
  height: 20px;
  display: none;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 28px;
  right: 24px;
  z-index: 5;
  &.open {
    & ~ nav > .menu {
      display: flex;
    }
    & ~ .navigation {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: 3;
    }
    & ~ .user-connect {
      opacity: 0;
      visibility: hidden;
    }
  }
  .hamburger {
    width: 20px;
    cursor: pointer;
    z-index: 2;
    &:before,
    &:after {
      content: '';
      background-color: white;
      display: block;
      height: 2px;
      border-radius: 3px;
      margin: 4px 0;
      transition: 0.5s;
    }
    &:after {
      width: 12px;
      margin-left: auto;
    }
    .divEmpty {
      background-color: white;
      display: block;
      height: 2px;
      border-radius: 3px;
      margin: 3px 0 3px auto;
      transition: 0.5s;
      width: 16px;
    }
    &.active {
      &::before {
        transform: translateY(6px) rotate(135deg);
        background-color: white;
      }
      &::after {
        transform: translateY(-6px) rotate(-135deg);
        background-color: white;
        width: 100%;
      }
      & .divEmpty {
        transform: scale(0);
        background-color: white;
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    display: flex;
    margin-left: 10px;
  }
`
export default Navbar
