import ColCs from 'components/Common/ColCs'
import RowCs from 'components/Common/RowCs'
import BlockIntroduce from 'components/Sections/BlockIntroduce'
import { IntroduceType } from 'constants/type'
import React from 'react'
import styled from 'styled-components'

interface PropsSectionIntroduce {
  content: IntroduceType
  image: string
}

const SectionIntroduce = (props: PropsSectionIntroduce) => {
  const { content, image } = props
  return (
    <SectionIntroduceWrapper>
      <RowCs space={-16}>
        <ColCs gutter={16} xs={12} md={5}>
          <ImageIntroduce>
            <BoxSquare>
              <img src={image} alt={'image-illustration'} />
            </BoxSquare>
          </ImageIntroduce>
        </ColCs>
        <ColCs gutter={16} xs={12} md={7}>
          <Information>
            <BlockIntroduce introduce={content} />
          </Information>
        </ColCs>
      </RowCs>
    </SectionIntroduceWrapper>
  )
}
const SectionIntroduceWrapper = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 150px;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 80px;
  }
`
const ImageIntroduce = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 100%;
  @media screen and (max-width: 767px) {
    height: 100%;
    padding-bottom: 0;
  }
`
const BoxSquare = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    display: block;
    vertical-align: middle;
  }
  @media screen and (max-width: 767px) {
    width: 200px;
    height: 200px;
    margin: 0 auto 32px;
    position: static;
  }
`
const Information = styled.div`
  padding-left: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    padding-left: 30px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 0;
  }
`
export default SectionIntroduce
