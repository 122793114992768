import { Heading3 } from 'components/Common/Typography'
import React from 'react'
import styled, { css } from 'styled-components'

interface HeadingType {
  content: string
  alignCenter?: boolean
}

const HeadingSection = (props: HeadingType) => {
  const { content, alignCenter } = props
  return (
    <HeadingSectionWrapper alignCenter={alignCenter ? alignCenter : false}>
      <Heading alignCenter={alignCenter ? alignCenter : false}>{content}</Heading>
    </HeadingSectionWrapper>
  )
}

const HeadingSectionWrapper = styled.div<{ alignCenter?: boolean }>`
  padding-bottom: 20px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100px;
    background-color: ${({ theme }) => theme.primary};
    bottom: 0;
    left: 0;
    ${({ alignCenter }) =>
      alignCenter &&
      css`
        left: 50%;
        transform: translateX(-50%);
      `}
  }
`
const Heading = styled(Heading3)<{ alignCenter?: boolean }>`
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      text-align: center;
    `};
`
export default HeadingSection
