import Card from 'components/Common/Card'
import ColCs from 'components/Common/ColCs'
import { Section, SectionWrapper } from 'components/Common/Containers'
import Reveal from 'components/Common/Reveal'
import RowCs from 'components/Common/RowCs'
import { TextNormal } from 'components/Common/Typography'
import HeadingSection from 'components/Sections/HeadingSection'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { removeDoubleApostrophe } from 'utils/removeDoubleApostrophe'
const OurService = () => {
  const { t } = useTranslation()
  return (
    <OurServiceWrapper id={'service-section'}>
      <Section>
        <DivFlex>
          <RichTextLeft>
            <Reveal>
              <HeadingSection content={t('home.ourService.heading')} />
            </Reveal>
            <Reveal>
              <Description>{t('home.ourService.description')}</Description>
            </Reveal>
          </RichTextLeft>
          <Services>
            <RowCs space={-12}>
              {Array.from(Array(4).keys()).map((index: number) => {
                const title = removeDoubleApostrophe(JSON.stringify(t(`home.ourService.services.${index}.title`)))
                const text = removeDoubleApostrophe(JSON.stringify(t(`home.ourService.services.${index}.text`)))
                const srcImg = `/assets/svg/services/service${index + 2}.svg`
                const service = {
                  title,
                  text,
                  srcImg,
                }
                return (
                  <ColCs xs={12} sm={6} gutter={12} className={'col-card'} key={index}>
                    <Card service={service} />
                  </ColCs>
                )
              })}
            </RowCs>
          </Services>
        </DivFlex>
      </Section>
    </OurServiceWrapper>
  )
}
const OurServiceWrapper = styled(SectionWrapper)`
  width: 100%;
`
const DivFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const RichTextLeft = styled.div`
  flex: 0 0 288px;
  max-width: 288px;
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`
const Description = styled(TextNormal)`
  margin-top: 16px;
`
const Services = styled.div`
  flex: 0 0 calc(100% - 288px);
  max-width: calc(100% - 288px);
  padding-left: 105px;
  padding-right: 12px;
  .col-card {
    margin-bottom: 12px;
    margin-top: 12px;
    & > div {
      height: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
`
export default OurService
