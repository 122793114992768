import styled from 'styled-components'

export const ButtonPrimary = styled.button`
  font-family: ${({ theme }) => theme.fontMontserrat};
  letter-spacing: -1px;
  color: ${({ theme }) => theme.dark};
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.25;
  font-weight: 500;
  padding: 15px 30px;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.primary};
  width: max-content;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s ease-out;
  &:hover {
    background-color: ${({ theme }) => theme.orange};
    color: ${({ theme }) => theme.white};
  }
`
export const ButtonPrimarySmall = styled(ButtonPrimary)`
  color: black;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 1.214;
  padding: 8px 24px;
  border-radius: 8px;
`
