import styled from 'styled-components'

export const FormLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.white};
  margin-bottom: 12px;
  display: block;
`
export const FormInput = styled.input`
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.orange};
  border-radius: 4px;
  outline: none;
  width: 100%;
  background-color: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 24px;
  &::placeholder {
    color: ${({ theme }) => theme.gray5};
    font-weight: 400;
  }
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    outline: 2px solid ${({ theme }) => theme.primary};
  }
`
export const FormInputSecondary = styled(FormInput)`
  border-color: ${({ theme }) => theme.primary};
  padding: 15px 16px;
  line-height: 17px;
  &::placeholder {
    color: ${({ theme }) => theme.white};
  }
`
export const FormTextarea = styled.textarea`
  width: 100%;
  padding: 12px 16px 20px 12px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.orange};
  background-color: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 24px;
  resize: none;
  border-radius: 4px;
  &::placeholder {
    color: ${({ theme }) => theme.gray5};
    font-weight: 400;
  }
  &:focus {
    outline: 2px solid ${({ theme }) => theme.primary};
  }
`
export const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
