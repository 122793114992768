import { TextNormal } from 'components/Common/Typography'
import { ProductType } from 'constants/type'
import React from 'react'
import styled from 'styled-components'

interface CardProductProps {
  product: ProductType
  heightEle: undefined | number
}

const CardProduct = (props: CardProductProps) => {
  const { product, heightEle } = props
  return (
    <CardProductWrapper className={'card-product flip-box'}>
      <FlipBoxInner className={'flip-box-inner'} heightEle={heightEle}>
        <FlipBoxFront className={'flip-box-front'}>
          <FlipContent className={'flip-content'}>
            <Name>{product.name}</Name>
            <Information>{product.information}</Information>
            <Illustrator>
              <img src={product.image} alt={'product'} />
            </Illustrator>
          </FlipContent>
        </FlipBoxFront>
        <FlipBoxBack className={'flip-box-back'}>
          <FlipContent>
            <Name>{product.name}</Name>
            {product?.detail && <Information>{product.detail}</Information>}
          </FlipContent>
        </FlipBoxBack>
      </FlipBoxInner>
    </CardProductWrapper>
  )
}
const CardProductWrapper = styled.div`
  height: 100%;
  position: relative;
  perspective: 500px;
  &:hover {
    .flip-box-inner {
      transform: rotateY(180deg);
    }
  }
  cursor: pointer;
`
const FlipBoxInner = styled.div<{ heightEle: undefined | number }>`
  width: 100%;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  height: ${({ heightEle }) => (heightEle ? `${heightEle}px` : '260px')};
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 12px;
`
const FlipBoxBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
`
const FlipContent = styled.div`
  padding: 16px 20px;
  ${({ theme }) => theme.flexColumnCenter};
  gap: 16px;
`
const FlipBoxFront = styled(FlipBoxBase)``
const FlipBoxBack = styled(FlipBoxBase)`
  transform: rotateY(180deg);
`
const Name = styled.p`
  color: ${({ theme }) => theme.primary};
  font-size: 22px;
  line-height: 27px;
  text-align: center;
`
const Information = styled(TextNormal)`
  text-align: center;
`
const Illustrator = styled.div`
  margin-top: auto;
  ${({ theme }) => theme.flexRowCenter};
  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
`
export default CardProduct
