import ColCs from 'components/Common/ColCs'
import { Section, SectionWrapper } from 'components/Common/Containers'
import RowCs from 'components/Common/RowCs'
import BlockContact from 'components/Sections/BlockContact'
import SectionIntroduce from 'components/Sections/SectionIntroduce'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { removeDoubleApostrophe } from 'utils/removeDoubleApostrophe'

import CardProduct from './CardProduct'

const products: { image: string }[] = [
  {
    image: '/assets/svg/icon-star.svg',
  },
  {
    image: '/assets/svg/firework-around.svg',
  },
  {
    image: '/assets/svg/icon-iconic-big.svg',
  },
  {
    image: '/assets/svg/round_border.svg',
  },
  {
    image: '/assets/svg/icon-six_star.svg',
  },
  {
    image: '/assets/svg/full_round.svg',
  },
]

const container = {
  show: {
    transition: {
      staggerChildren: 0.5,
    },
  },
}
const item = {
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
    },
  },
}
const Products = () => {
  const { t } = useTranslation()
  const [heightEle, setHeightEle] = useState<undefined | number>(undefined)
  useEffect(() => {
    const setHeightElement = () => {
      const flipContents = Array.from(document.querySelectorAll('.flip-box .flip-content'))
      let maxHeight = flipContents[0].getBoundingClientRect().height
      for (let i = 0; i < flipContents.length - 1; i++) {
        const { height } = flipContents[i].getBoundingClientRect()
        maxHeight = height > maxHeight ? height : maxHeight
      }
      setHeightEle(maxHeight + 20)
    }
    setHeightElement()
    window.addEventListener('resize', setHeightElement)
    return () => {
      window.removeEventListener('resize', setHeightElement)
    }
  }, [])
  return (
    <ProductsWrapper>
      <Section>
        <SectionIntroduce
          content={{
            subTitle: removeDoubleApostrophe(JSON.stringify(t('products.content.subTitle'))),
            title: removeDoubleApostrophe(JSON.stringify(t('products.content.title'))),
            description: removeDoubleApostrophe(JSON.stringify(t('products.content.description'))),
            link: {
              href: 'https://dandelionlabs.io/our-products/',
              name: removeDoubleApostrophe(JSON.stringify(t('button.explore'))),
            },
          }}
          image={'/assets/svg/token-vesting.svg'}
        />
      </Section>
      <SectionWrapper>
        <Section>
          <ListProduct>
            <motion.div variants={container} initial="hidden" exit="exit" whileInView="show" viewport={{ once: true }}>
              <RowCs space={-15} className={'row-product'}>
                {Array.from(Array(6).keys()).map((index: number) => {
                  const product = {
                    name: removeDoubleApostrophe(JSON.stringify(t(`products.productList.${index}.name`))),
                    information: removeDoubleApostrophe(JSON.stringify(t(`products.productList.${index}.information`))),
                    detail: removeDoubleApostrophe(JSON.stringify(t(`products.productList.${index}.detail`))),
                    image: products[index].image,
                  }
                  return (
                    <ColCs key={index} gutter={15} xs={12} sm={6} md={4} className={'col-product'}>
                      <motion.div variants={item}>
                        <CardProduct product={product} heightEle={heightEle} />
                      </motion.div>
                    </ColCs>
                  )
                })}
              </RowCs>
            </motion.div>
          </ListProduct>
        </Section>
      </SectionWrapper>
      <SectionWrapper>
        <BlockContact />
      </SectionWrapper>
    </ProductsWrapper>
  )
}
const ProductsWrapper = styled.div`
  width: 100%;
`
const ListProduct = styled.div`
  .col-product {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 1024px) {
    .col-product {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    .row-product {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
`
export default Products
