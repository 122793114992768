import CardDotted from 'components/Common/CardDotted'
import { Heading5, TextSmall } from 'components/Common/Typography'
import { CareerType } from 'constants/type'
import React from 'react'
import styled from 'styled-components'

interface CardCareerProps {
  career: CareerType
}

const CardCareer = (props: CardCareerProps) => {
  const { career } = props
  return (
    <CardCareerBox className={'card-career'}>
      <CardContent>
        <Illustrator>
          <img src={career.image} alt={'product'} />
        </Illustrator>
        <Name>{career.name}</Name>
        <Information>{career.information}</Information>
      </CardContent>
    </CardCareerBox>
  )
}
const CardCareerBox = styled(CardDotted)`
  padding: 24px;
  position: relative;
`
const CardContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
`
const Name = styled(Heading5)`
  line-height: 1.2;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.white};
  text-align: center;
`
const Information = styled(TextSmall)`
  text-align: center;
`
const Illustrator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  height: 100px;
  img {
    max-height: 100px;
    object-fit: cover;
  }
`
export default CardCareer
