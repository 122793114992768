import { IMAGES } from 'assets/images'
import ColCs from 'components/Common/ColCs'
import { ContainerLarge, SectionWrapper } from 'components/Common/Containers'
import Reveal from 'components/Common/Reveal'
import RowCs from 'components/Common/RowCs'
import { TextNormal } from 'components/Common/Typography'
import HeadingSection from 'components/Sections/HeadingSection'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const partners = [
  {
    src: IMAGES.EVOTEK,
    link: 'https://evotek.vn/',
  },
  {
    src: IMAGES.THE_STORM_NETWORK,
    link: 'https://thestormnetwork.com/en',
  },
  {
    src: IMAGES.REMINISCENSE,
    link: 'https://reminiscense.org/',
  },
  {
    src: IMAGES.BLOCKCHAIN_HANOI,
    link: 'https://blockchainhanoi.org/',
  },
  {
    src: IMAGES.ET_HEADER,
    link: 'https://equitiestracker.com/',
  },
  {
    src: IMAGES.SMU,
    link: 'https://www.smu.edu.sg/',
  },
  {
    src: IMAGES.OXYCHAIN,
    link: 'https://oxychain.earth/',
  },
  {
    src: IMAGES.SUPERLOGS,
    link: 'https://superalgos.org/',
  },
  {
    src: IMAGES.LAN_CAPITAL,
    link: 'https://www.lantocapital.com/',
  },
  {
    src: IMAGES.TSS,
    link: 'https://tssnft.com/',
  },
  {
    src: IMAGES.OXY_FARMS,
    link: 'https://oxyfarms.earth/',
  },
  {
    src: IMAGES.ANOTHER1,
    link: 'https://www.another-1.io/',
  },
  {
    src: IMAGES.VSMAC,
    link: 'https://vsmac.com/en/',
  },
  {
    src: IMAGES.DEGEN,
    link: 'https://degengang.io/',
  },
  {
    src: IMAGES.LAN_SPACE,
    link: 'https://www.thelanspace.com/',
  },
  {
    src: IMAGES.ARTTACA,
    link: 'https://arttaca.io/',
  },
]
const Partners = () => {
  const { t } = useTranslation()
  return (
    <SectionWrapper>
      <HeadingPartners>
        <Reveal>
          <HeadingSection content={t('home.partners.heading')} alignCenter={true} />
        </Reveal>
        <Reveal>
          <Description>{t('home.partners.description')}</Description>
        </Reveal>
      </HeadingPartners>
      <ContainerLarge>
        <ListPartners>
          {partners.map((item, index: number) => {
            return (
              <ItemPartner xs={6} sm={4} md={3} key={index}>
                <a href={item.link} target={'_blank'} rel={'noreferrer'}>
                  <img src={item.src} alt={'partner_logo'} />
                </a>
              </ItemPartner>
            )
          })}
        </ListPartners>
      </ContainerLarge>
    </SectionWrapper>
  )
}
const HeadingPartners = styled.div`
  max-width: 671px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => theme.flexColumnCenter};
  @media screen and (max-width: 767px) {
    padding-left: 24px;
    padding-right: 24px;
    h3 {
      text-align: center;
      font-size: ${({ theme }) => theme.heading4};
    }
  }
`
const Description = styled(TextNormal)`
  margin-top: 16px;
  text-align: center;
`
const ListPartners = styled(RowCs)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
`
const ItemPartner = styled(ColCs)`
  margin-bottom: 12px;
  padding: 25px;
  ${({ theme }) => theme.flexRowCenter};
  transition: background-color 0.25s ease-in;
  &:hover {
    background-color: ${({ theme }) => theme.gray3};
  }
  a {
    text-decoration: none;
    width: 80%;
    display: block;
  }
  img {
    max-width: 80%;
    margin: 0 auto;
    height: 100%;
    max-height: 150px;
    display: block;
  }
  @media only screen and (max-width: 1199px) {
    a {
      width: 80%;
    }
  }
  @media only screen and (max-width: 1023px) {
    padding: 12px;
    a {
      width: 80%;
    }
  }
`
export default Partners
