import ColCs from 'components/Common/ColCs'
import { Container } from 'components/Common/Containers'
import RowCs from 'components/Common/RowCs'
import { Heading6, TextSmall } from 'components/Common/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const menu = [
  {
    link: '/about',
  },
  {
    link: '/products',
  },
  {
    link: '/careers',
  },
  {
    link: '/blog',
  },
]
const socials = [
  {
    link: 'https://www.facebook.com/dandelionlabs.io',
    icon: '/assets/svg/socials/icon-fb.svg',
  },
  {
    link: 'https://twitter.com/dandelionlabsio',
    icon: '/assets/svg/socials/icon-twitter.svg',
  },
  {
    link: 'https://linkedin.com/company/dandelionlabs',
    icon: '/assets/svg/socials/icon-linkedin.svg',
  },
  {
    link: 'https://www.instagram.com/dandelionlabs.io/',
    icon: '/assets/svg/socials/icon-instagram.svg',
  },
]
const Footer = () => {
  const { t } = useTranslation()
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterContent>
          <RowCs noSpace={true}>
            <ColCs noGutter={true} xs={12} md={5} xl={6} className={'col-footer'}>
              <ContentLeft>
                <img src="/assets/svg/firework_footer.svg" alt="firework" />
              </ContentLeft>
            </ColCs>
            <ColCs noGutter={true} xs={12} md={7} xl={6} className={'col-footer'}>
              <ContentRight>
                <Box>
                  <Navigation>
                    <Menu>
                      {Array.from(Array(4).keys()).map((index: number) => {
                        return (
                          <MenuItem key={index}>
                            <Link to={menu[index].link}>{t(`footer.navigation.${index}.name`)}</Link>
                          </MenuItem>
                        )
                      })}
                    </Menu>
                  </Navigation>
                  <Information>
                    <InforBasic>
                      <Heading6>{t('footer.contactUs')}</Heading6>
                      <a href="tel:+84034378893">+84 (0) 343 788-923</a>
                      <a href={'mailto:hello@dandelionlabs.io'}>hello@dandelionlabs.io</a>
                      <Socials>
                        {socials.map((social, index) => {
                          return (
                            <Link to={social.link} key={index} target="_blank" rel="noopener noreferrer">
                              <img src={social.icon} alt={'social'} />
                            </Link>
                          )
                        })}
                      </Socials>
                    </InforBasic>
                    <InforBasic>
                      <Heading6>{t('footer.visitUs')}</Heading6>
                      <span>
                        Building Kim Hoan
                        <br />
                        Ngo 19 Duy Tan
                        <br />
                        Cau Giay, Hanoi
                      </span>
                    </InforBasic>
                  </Information>
                </Box>
                <BoxImage>
                  <Link to={'/'}>
                    <img src="/assets/svg/logo_vertical.svg" alt="Logo" />
                  </Link>
                </BoxImage>
              </ContentRight>
              <DivNote>
                <SpanText>{t('footer.privacy')}</SpanText>
                <SpanText>{t('footer.terms')}</SpanText>
                <Copyright>{t('footer.copyright')}</Copyright>
              </DivNote>
            </ColCs>
          </RowCs>
        </FooterContent>
      </FooterContainer>
    </FooterWrapper>
  )
}

export default Footer

const FooterWrapper = styled.footer`
  position: relative;
  padding-top: 80px;
  @media screen and (max-width: 767px) {
    padding-top: 40px;
  }
`
const FooterContainer = styled(Container)`
  padding-left: 25px;
`
const FooterContent = styled.div`
  width: 100%;
  .col-footer {
    align-self: flex-end;
  }
`
const ContentLeft = styled.div`
  img {
    width: 100%;
    display: block;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
    img {
      width: 300px;
      margin: 0 auto;
    }
  }
`
const ContentRight = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Navigation = styled.div``
const Information = styled.div`
  display: flex;
  margin-top: 60px;
`
const InforBasic = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  &:last-of-type {
    padding-left: 20px;
  }
  h6 {
    color: ${({ theme }) => theme.white};
    margin-bottom: 20px;
  }
  a,
  span {
    font-size: ${({ theme }) => theme.fontSizeText3};
    color: ${({ theme }) => theme.gray};
    line-height: 17px;
  }
  a {
    text-decoration: none;
    display: block;
    margin-bottom: 8px;
  }
`
const Box = styled.div`
  padding: 20px 40px 20px 0;
  border-right: 1px solid ${({ theme }) => theme.primary};
  flex: 0 0 calc(100% - 195px);
  max-width: calc(100% - 195px);
  @media screen and (max-width: 1299px) {
    padding-right: 20px;
  }
  @media screen and (max-width: 1024px) {
    flex: 0 0 calc(100% - 140px);
    max-width: calc(100% - 140px);
  }
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    border-right: none;
    border-bottom: 1px solid ${({ theme }) => theme.primary};
  }
`
const BoxImage = styled.div`
  flex: 0 0 195px;
  max-width: 195px;
  padding-left: 40px;
  align-self: center;
  img {
    width: 100%;
    cursor: pointer;
  }
  @media screen and (max-width: 1299px) {
    padding-left: 20px;
  }
  @media screen and (max-width: 1024px) {
    flex: 0 0 140px;
    max-width: 140px;
  }
  @media screen and (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 32px;
    padding-left: 0;
    img {
      max-width: 200px;
      display: block;
      margin: 0 auto;
    }
  }
`
const Menu = styled.ul`
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
`
const MenuItem = styled.li`
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 17px;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  text-transform: capitalize;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.white};
    transition: color 0.25s ease-in;
    &:hover {
      color: ${({ theme }) => theme.primary};
    }
    &.active {
      color: ${({ theme }) => theme.primary};
    }
  }
`
const DivNote = styled.div`
  margin: 100px 0 32px;
  display: flex;
  @media screen and (max-width: 1299px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
const SpanText = styled(TextSmall)`
  color: ${({ theme }) => theme.gray2};
  &:first-of-type {
    margin-right: 16px;
  }
`
const Copyright = styled(TextSmall)`
  color: ${({ theme }) => theme.gray2};
  margin-left: auto;
  @media screen and (max-width: 575px) {
    margin-top: 16px;
    margin-left: 0;
  }
`
const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 140px;
  width: 100%;
  a {
    img {
      transition: all 0.25s ease-in;
      &:hover {
        transform: scale(1.1);
        transform-origin: center;
        filter: brightness(2);
      }
    }
  }
`
