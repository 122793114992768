import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1440px;
  padding-left: 160px;
  padding-right: 160px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1299px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`
export const ContainerLarge = styled.div`
  max-width: 1440px;
  padding-left: 126px;
  padding-right: 126px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1299px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`
export const ContainerFluid = styled.div`
  width: 100%;
`

export const Section = styled.section`
  max-width: 1440px;
  padding-left: 200px;
  padding-right: 200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1299px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media screen and (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`
export const SectionWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  @media screen and (max-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`
