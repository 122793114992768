import Benefits from './Benefits'
import Gallery from './Gallery'
import Hero from './Hero'
import OurProducts from './OurProducts'
import OurService from './OurService'
import Partners from './Partners'
import Testimonials from './Testimonials'

const Home = () => {
  return (
    <div>
      <Hero />
      <OurService />
      <Gallery />
      <OurProducts />
      <Benefits />
      <Testimonials />
      <Partners />
    </div>
  )
}

export default Home
