import { SectionWrapper } from 'components/Common/Containers'
import Reveal from 'components/Common/Reveal'
import { TextNormal } from 'components/Common/Typography'
import HeadingSection from 'components/Sections/HeadingSection'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SocialResponsibility = () => {
  const { t } = useTranslation()
  return (
    <SectionWrapper>
      <ResponsibilityMain>
        <ResponsibilityContent>
          <Reveal>
            <HeadingSection content={'Social Responsibility'} />
          </Reveal>
          <Description dangerouslySetInnerHTML={{ __html: t('about.responsibility') }} />

          <Image src={'/assets/images/blockchain-community.png'} alt={'logo_community_hanoi'} />
        </ResponsibilityContent>
        <ResponsibilityMedia>
          <BoxMedia>
            <MediaItem>
              <MediaRatio>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/SLhDouRuZ0s"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </MediaRatio>
            </MediaItem>
            <MediaItem>
              <MediaRatio>
                <img src={'/assets/images/community.png'} alt={'community_hanoi'} />
              </MediaRatio>
            </MediaItem>
          </BoxMedia>
        </ResponsibilityMedia>
      </ResponsibilityMain>
    </SectionWrapper>
  )
}
const ResponsibilityMain = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const ResponsibilityContent = styled.div`
  flex: 0 0 393px;
  max-width: 393px;
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`
const Description = styled(TextNormal)`
  margin-top: 16px;
  margin-bottom: 28px;
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.primary};
  }
`
const Image = styled.img`
  max-width: 248px;
`
const ResponsibilityMedia = styled.div`
  flex: 0 0 calc(100% - 393px);
  max-width: calc(100% - 393px);
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 24px;
  }
`
const BoxMedia = styled.div`
  width: 100%;
  padding-left: 20%;
  @media screen and (max-width: 1024px) {
    padding-left: 10%;
  }
  @media screen and (max-width: 767px) {
    padding-left: 0;
  }
`
const MediaItem = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;
  position: relative;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 12px;
  overflow: hidden;
  &:first-of-type {
    margin-bottom: 48px;
  }
`
const MediaRatio = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`
export default SocialResponsibility
