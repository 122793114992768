import { SectionWrapper } from 'components/Common/Containers'
import BlockContact from 'components/Sections/BlockContact'
import React from 'react'

const Benefits = () => {
  return (
    <SectionWrapper>
      <BlockContact />
    </SectionWrapper>
  )
}
export default Benefits
