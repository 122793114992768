import styled from 'styled-components'
export const TextBase = styled.p`
  font-style: normal;
  line-height: normal;
  font-weight: 400;
`
export const Heading1 = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.heading1};
  line-height: 1.5;
  margin: 0;
`

export const Heading2 = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.heading2};
  line-height: 1.136;
  margin: 0;
  @media only screen and (max-width: 575px) {
    font-size: ${({ theme }) => theme.heading3};
    line-height: 1.25;
  }
`
export const Heading3 = styled.h3`
  font-weight: 600;
  font-size: ${({ theme }) => theme.heading3};
  line-height: 1.206;
  margin: 0;
  @media only screen and (max-width: 575px) {
    font-size: ${({ theme }) => theme.heading4};
    line-height: 1.25;
  }
`
export const Heading4 = styled.h4`
  font-weight: 600;
  font-size: ${({ theme }) => theme.heading4};
  line-height: 1.25;
  margin: 0;
`
export const Heading5 = styled.h5`
  font-weight: 500;
  font-size: ${({ theme }) => theme.heading5};
  line-height: 1.25;
  margin: 0;
`
export const Heading6 = styled.h6`
  font-weight: 500;
  font-size: ${({ theme }) => theme.heading6};
  line-height: 1.25;
  margin: 0;
`
export const Display1 = styled(TextBase)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.display1};
  line-height: 1.23;
  margin: 0;
`
export const TextLarge = styled(TextBase)`
  font-size: ${({ theme }) => theme.display2};
  color: ${({ theme }) => theme.gray};
  line-height: 1.44;
  margin: 0;
`
export const TextNormal = styled(TextBase)`
  font-size: ${({ theme }) => theme.fontSizeText2};
  color: ${({ theme }) => theme.gray};
  line-height: 1.25;
  margin: 0;
  @media only screen and (max-width: 575px) {
    font-size: ${({ theme }) => theme.fontSizeText3};
  }
`
export const TextSmall = styled(TextBase)`
  font-size: ${({ theme }) => theme.fontSizeText4};
  color: ${({ theme }) => theme.gray};
  line-height: 1.25;
  margin: 0;
`
