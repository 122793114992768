import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from 'constants/ApiConstants'

export const contactSlice = createApi({
  tagTypes: ['Contact'],
  reducerPath: 'Contact',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (build) => ({
    sendContact: build.mutation({
      query: (contact: Partial<{ name: string; subject: string; email: string; message: string }>) => {
        return {
          url: 'contact',
          method: 'POST',
          body: contact,
        }
      },
    }),
  }),
})
export const { useSendContactMutation } = contactSlice
