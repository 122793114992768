import { motion, useAnimation, useInView } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
interface PropsReveal {
  children: React.ReactNode
  width?: 'fit-content' | '100%'
}
const Reveal = ({ children, width = 'fit-content' }: PropsReveal) => {
  const refDiv = useRef<HTMLDivElement | null>(null)
  const isInView = useInView(refDiv, { once: true })
  const mainControls = useAnimation()
  const slideControls = useAnimation()
  useEffect(() => {
    if (isInView) {
      mainControls.start('visible')
      slideControls.start('visible')
    }
  }, [isInView, mainControls, slideControls])
  return (
    <div ref={refDiv} style={{ position: 'relative', width, overflow: 'hidden' }}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 0.6 },
          visible: { opacity: 1, y: 0 },
        }}
        initial={'hidden'}
        animate={mainControls}
        transition={{ duration: 0.75, delay: 0.3 }}
      >
        {children}
      </motion.div>
      <motion.div
        variants={{
          hidden: { left: 0 },
          visible: { left: '100%' },
        }}
        initial={'hidden'}
        animate={slideControls}
        transition={{ duration: 0.5, ease: 'easeIn' }}
        style={{
          position: 'absolute',
          top: 4,
          left: 0,
          right: 0,
          bottom: 4,
          zIndex: 20,
          backgroundColor: '#ff6d00',
        }}
      />
    </div>
  )
}

export default Reveal
