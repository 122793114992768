import { Section, SectionWrapper } from 'components/Common/Containers'
import BlockContact from 'components/Sections/BlockContact'
import SectionIntroduce from 'components/Sections/SectionIntroduce'
import { PathConstants } from 'constants/PathConstants'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { removeDoubleApostrophe } from 'utils/removeDoubleApostrophe'

import SocialResponsibility from './SocialResponsibility'

const Index = () => {
  const { t } = useTranslation()
  return (
    <AboutWrapper>
      <Section>
        <SectionIntroduce
          content={{
            subTitle: removeDoubleApostrophe(JSON.stringify(t('about.content.subTitle'))),
            title: removeDoubleApostrophe(JSON.stringify(t('about.content.title'))),
            description: removeDoubleApostrophe(JSON.stringify(t('about.content.description'))),
            link: {
              href: PathConstants.CONTACT,
              name: removeDoubleApostrophe(JSON.stringify(t('button.talkToUs'))),
            },
          }}
          image={'/assets/svg/firework-around.svg'}
        />
      </Section>
      <Section>
        <SocialResponsibility />
      </Section>
      <SectionWrapper>
        <BlockContact />
      </SectionWrapper>
    </AboutWrapper>
  )
}
const AboutWrapper = styled.div`
  width: 100%;
`
export default Index
