import { ButtonPrimarySmall } from 'components/Common/Button'
import { Heading5, TextSmall } from 'components/Common/Typography'
import React from 'react'
import styled from 'styled-components'

const CardBlog = (props: any) => {
  const { blog } = props
  return (
    <CardBlogWrapper className={'card-blog'}>
      <CardContent>
        <Illustrator>
          <ButtonBlockchain>Blockchain</ButtonBlockchain>
          <DivRatio>
            <img src={blog.image} alt={'product'} />
          </DivRatio>
        </Illustrator>
        <Name>{blog.name}</Name>
        <Information>{blog.information}</Information>
      </CardContent>
    </CardBlogWrapper>
  )
}
const CardBlogWrapper = styled.div`
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.primary};
  padding-bottom: 24px;
`
const CardContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
`
const Name = styled(Heading5)`
  line-height: 1.2;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.white};
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
`
const Information = styled(TextSmall)`
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
`
const Illustrator = styled.div`
  padding-bottom: 17px;
  position: relative;
  margin-bottom: 24px;
`
const ButtonBlockchain = styled(ButtonPrimarySmall)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
`
const DivRatio = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
  overflow: hidden;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
  & > img {
    display: block;
    width: 100%;
    border-radius: 12px 12px 0 0;
  }
`
export default CardBlog
