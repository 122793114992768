import ColCs from 'components/Common/ColCs'
import { Section, SectionWrapper } from 'components/Common/Containers'
import { ArrowPagination, PaginationCs } from 'components/Common/PaginationCs'
import RowCs from 'components/Common/RowCs'
import SectionIntroduce from 'components/Sections/SectionIntroduce'
import Pagination from 'rc-pagination'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { removeDoubleApostrophe } from 'utils/removeDoubleApostrophe'

import BlockRegisterInformation from './BlockRegisterInformation'
import CardBlog from './CardBlog'

const blogs: { image: string }[] = [
  {
    image: '/assets/images/blog/game.png',
  },
  {
    image: '/assets/images/blog/digital.png',
  },
  {
    image: '/assets/images/blog/token.png',
  },
  {
    image: '/assets/images/blog/game.png',
  },
  {
    image: '/assets/images/blog/digital.png',
  },
  {
    image: '/assets/images/blog/token.png',
  },
  {
    image: '/assets/images/blog/game.png',
  },
  {
    image: '/assets/images/blog/digital.png',
  },
  {
    image: '/assets/images/blog/token.png',
  },
]
const Blog = () => {
  const { t } = useTranslation()
  return (
    <BlogWrapper>
      <Section>
        <SectionIntroduce
          content={{
            subTitle: removeDoubleApostrophe(JSON.stringify(t('blog.content.subTitle'))),
            title: removeDoubleApostrophe(JSON.stringify(t('blog.content.title'))),
            description: removeDoubleApostrophe(JSON.stringify(t('blog.content.description'))),
          }}
          image={'/assets/svg/round_border.svg'}
        />
      </Section>
      <SectionWrapper>
        <Section>
          <ListCareers>
            <RowCs space={-16}>
              {Array.from(Array(9).keys()).map((index: number) => {
                const blog = {
                  name: JSON.stringify(t(`blog.blogList.${index}.name`)),
                  information: JSON.stringify(t(`blog.blogList.${index}.information`)),
                  image: blogs[index].image,
                }
                return (
                  <ColCs key={index} gutter={16} xs={12} sm={6} lg={4} className={'col-card'}>
                    <CardBlog blog={blog} />
                  </ColCs>
                )
              })}
            </RowCs>
          </ListCareers>
          <PaginationCs>
            <Pagination
              // onChange={(current, pageSize) => {
              //   handleChangePage(current, pageSize)
              // }}
              pageSize={10}
              //current={currentPage}
              total={40}
              prevIcon={() => <ArrowPagination>{t('pagination.prev')}</ArrowPagination>}
              nextIcon={() => <ArrowPagination>{t('pagination.next')}</ArrowPagination>}
            />
          </PaginationCs>
        </Section>
      </SectionWrapper>
      <SectionWrapper>
        <BlockRegisterInformation />
      </SectionWrapper>
    </BlogWrapper>
  )
}
const BlogWrapper = styled.div`
  width: 100%;
`
const ListCareers = styled.div`
  .col-card {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .card-blog {
    height: 100%;
  }
`
export default Blog
