import 'rc-pagination/assets/index.css'

import styled from 'styled-components'

export const PaginationCs = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;

  & button {
    background: none !important;
    border: none !important;
    &:after {
      margin-top: -5px;
      display: inline-flex;
      font-size: 25px !important;
      color: ${({ theme }) => theme.white};
    }

    &[disabled] {
      &:after {
        color: ${({ theme }) => theme.dark1} !important;
      }
    }
  }

  & .rc-pagination-item {
    min-width: 40px;
    height: 40px;
    border-radius: 0;
    padding: 8px;
    background: none !important;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    color: ${({ theme }) => theme.gray6};

    &:hover {
      background: ${({ theme }) => theme.primary};

      a {
        color: ${({ theme }) => theme.primary};
      }
    }

    & a {
      color: ${({ theme }) => theme.white};
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: ${({ theme }) => theme.display2};
      line-height: 28px;
    }

    &-active {
      background-color: ${({ theme }) => theme.primary} !important;
      border-radius: 6px;

      &:hover {
        a {
          color: ${({ theme }) => theme.white};
        }
      }
      & a {
        color: ${({ theme }) => theme.dark};
      }
    }
    @media (max-width: 767px) {
      min-width: 32px;
      height: 32px;
      padding: 2px;
      margin-left: 4px;
      margin-right: 4px;
    }
  }
  & .rc-pagination-simple-pager {
    color: ${({ theme }) => theme.white};

    input,
    span {
      background: none;
      color: ${({ theme }) => theme.white};
      border: none;
      font-size: 14px !important;
    }

    input {
      padding: 0;
      margin-right: -5px;
      font-size: 15px !important;
    }
  }
  & .rc-pagination-jump-next {
    margin-right: 0;
    .rc-pagination-item-link::after {
      font-size: ${({ theme }) => theme.fontSizeText2};
      color: ${({ theme }) => theme.dark1};
    }
  }
  & .rc-pagination-prev {
    margin-right: 0;
  }
  .rc-pagination-disabled {
    opacity: 0.4;
  }
  @media (max-width: 767px) {
    button.rc-pagination-item-link::after {
      font-size: ${({ theme }) => theme.fontSizeText2} !important;
    }
  }
`
export const ArrowPagination = styled.span`
  font-family: ${({ theme }) => theme.fontRoboto};
  font-size: ${({ theme }) => theme.display2};
  line-height: 22px;
  color: ${({ theme }) => theme.primary};
  font-weight: 800;
`
